import styled from "@emotion/styled";
import {
  Pane,
  BetPane,
  OneStrike,
  TwoStrike,
  NoStrike,
  WIN,
} from "assets/images";
import { sizes } from "service";
import { mixins } from "theme";

export const GameOverContainer = styled.div`
  position: relative;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .saving-loader {
    position: absolute;
    z-index: 1;
    color: white;
    .ant-spin-dot {
      .ant-spin-dot-item {
        background-color: white;
      }
    }
  }
  .rolling-wrapper {
    width: ${sizes.mainWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .gameover-screen {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 1000;
      .win-stars-wrapper {
        width: 100%;
        height: 250px;
        position: relative;
        .stars-container {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .win-ray {
          position: absolute;
          top: -100px;
          z-index: 0;
          animation-name: spin;
          animation-duration: 5000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }
        .win-mark {
          /* position: absolute; */
          width: ${sizes.mainWidth - 140}px;
          height: 170px;
          background: url(${WIN});
          background-size: cover;
          top: -88px;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            filter: drop-shadow(0px 5px 2.5px rgba(255, 255, 255, 0.4));
          }
          .win-money {
            position: relative;
            text-align: center;
            bottom: 32px;
          }
        }
        .option-btns {
          display: flex;
          z-index: 2;
        }
      }
    }
    .jackpot-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bet-pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${BetPane});
      background-size: 100% 100%;
      margin: 20px 0;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      .bet-ins-mask {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 20px 40px;
        .bet-ins-mask-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .fake-dice {
            position: absolute;
            left: 0;
            top: 77px;
          }
          .handler-bet {
            position: absolute;
            bottom: -41px;
          }
        }
      }
      .bet-pane-title {
        font-size: 22px;
        /* padding: 0 33px; */
        color: #ffff45;
        background: linear-gradient(
          360deg,
          #fbe741 25%,
          #dba323 53%,
          #fbe741 72%
        );
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        font-weight: 900;
        font-family: "Roboto";
        text-align: center;
        filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
        text-transform: uppercase;
        /* z-index: 1001; */
        /* z-index: 1; */
      }
      .bet-pane-description {
        color: white;
        font-size: 18px;
        font-family: "Roboto";
        text-align: center;
      }
      span {
        font-family: "Roboto";
        font-weight: 700;
        color: white;
        margin-bottom: 9px;
      }
      .one-two-strikes {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        margin-bottom: 20px;
        .bet-pane-card-title {
          font-size: 16px;
          color: #ffff45;
          background: linear-gradient(
            360deg,
            #fbe741 25%,
            #dba323 53%,
            #fbe741 72%
          );
          -webkit-text-fill-color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          font-weight: 900;
          font-family: "Roboto";
          text-align: center;
          filter: drop-shadow(0px 10px 10px rgba(0, 71, 147, 0.5));
          text-transform: uppercase;
          z-index: 1001;
        }
        .one-strike-wrapper {
          flex: 1;
          .one-strike {
            height: 60px;
            background: url(${OneStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
        .two-strike-wrapper {
          flex: 1;
          .two-strike {
            height: 60px;
            background: url(${TwoStrike});
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 20px;
          }
        }
      }
      .no-strikes {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 55px;
        span {
          font-size: 30px;
        }
        .no-strike-bg {
          width: 80%;
          height: 80px;
          background: url(${NoStrike});
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }
    .pane-container {
      position: relative;
      width: ${sizes.mainWidth}px;
      height: ${sizes.mainWidth}px;
      background: url(${Pane});
      background-size: 100% 100%;
      // margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .free-pane {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .stop-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 1;
      }
      .ins-mask {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        min-height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 2;
        padding: 40px;
        .ins-mask-header {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          .icon-bone {
            position: absolute;
            left: 0px;
          }
          .ins-mask-title {
            @media (max-width: ${mixins.mobileWidth}px) {
              font-size: 22px !important;
            }
          }
        }
        .btn-gotit {
          margin-top: 15px;
          cursor: pointer;
        }
      }
      .skipping-stars {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      .pane {
        padding: 15px;
        .ant-row {
          .ant-col {
          }
        }
      }
      .pane-mask {
        position: absolute;
        bottom: -20px;
        background: rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        border: solid dimgray 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 44px;
      }
    }
    .dice {
      display: flex;
      justify-content: center;
      .die-container {
        .die {
          transition: transform 0.9s ease;
          .face {
            box-shadow: inset -0.35rem 0.35rem 0.75rem rgba(0, 0, 0, 0.3),
              inset 0.5rem -0.25rem 0.5rem rgba(0, 0, 0, 0.15);
            border-radius: 13px;
            border: solid 1px white;
          }
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 100%;
      min-height: 105px;
      .guide-img,
      .paytable-img {
        min-width: 50px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  /* @media (max-width: 412px) {
    .rolling-wrapper {
      .pane-container {
        width: 300px;
        height: 300px;
      }
      .btn-container {
        min-height: 85px;
        .roll-btn {
          width: 185px !important;
          height: 80px !important;
          span {
            font-size: 24px !important;
          }
        }
      }
    }
  } */
  @media (max-width: ${mixins.mobileWidth}px) {
    margin-top: 0px;
    .rolling-wrapper {
      .pane-container {
        width: ${sizes.mainWidthM}px;
        height: ${sizes.mainWidthM}px;
        margin: 10px 0;
        .pane-mask {
          bottom: -8px;
          padding: 6px 44px;
          span {
            font-size: 14px !important;
          }
        }
      }
      .bet-pane-container {
        width: ${sizes.mainWidthM}px;
        height: ${sizes.mainWidthM}px;
        .bet-pane-title {
          font-size: 20px;
        }
        .no-strikes {
          margin-bottom: 25px;
        }
      }
      .holes-container {
        margin-top: 12px;
        .holes-mask {
          top: -36px;
          span {
            font-size: 12px !important;
          }
        }
      }
      .btn-container {
        min-height: 58px;
        .roll-btn {
          width: 157px !important;
          height: 57px !important;
          span {
            font-size: 18px !important;
          }
        }
      }
    }
  }
`;
